<template>
  <div class="flex full-width justify-center">
    <sz-logo :logo="logo" alignment="center" alt="logo" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { getLogo } from 'src/helpers/utils';
import { SzLogo } from '@sz4/sz-components'

export default defineComponent({
  name: 'Logo',

  components: {
    SzLogo
  },

  setup() {
    const { logo } = getLogo();

    return { logo };
  }
});
</script>
